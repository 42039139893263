import React from 'react'
import NavBar from '../Components/Navbar/NavBar'
import CompanyServices from '../Components/CompanyServices/CompanyServices'
import Footer from '../Components/footer/Footer'
const CompanyseComp = () => {

  return (
    <div>
        <NavBar />
        <CompanyServices/>
        <Footer/>
      
    </div>
  )
}

export default CompanyseComp
