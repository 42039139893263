import React from 'react'
import Media from '../Components/Media/media'
import NavBar from '../Components/Navbar/NavBar'
import Footer from '../Components/footer/Footer'

const MediaComp = () => {
  return (
    <div>
      <NavBar/>
      <Media/>
      <Footer/>
      
    </div>
  )
}

export default MediaComp
