import React from 'react'
import NavBar from '../Components/Navbar/NavBar'
import Home from '../Components/Home/Home'
import Footer from '../Components/footer/Footer'


const HomeComp = () => {

  
  return (
    <div>
      <NavBar />
      <Home/>
      <Footer/>

      
    </div>
  )
}

export default HomeComp
