import React from 'react'
import NavBar from '../Components/Navbar/NavBar'
import Who from '../Components/Who/Who'
import Footer from '../Components/footer/Footer';

const WhoComp = () => {

  
  return (
    <div>
      <NavBar  />
      <Who/>
      <Footer />
      
    </div>
  )
}

export default WhoComp
